export const env = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || "http://localhost:1337",
  appName:
    process.env.REACT_APP_APP_NAME || "Bindulogic Enterprise Application",
  openmrsLink: process.env.REACT_APP_OPENMRS_LINK || "",
  organizationLogo: process.env.REACT_APP_ORGANIZATION_LOGO || "",
  organizationName: process.env.REACT_APP_ORGANIZATION_NAME || "",
  eTicketingLink: process.env.REACT_APP_E_TICKETING_CLIENT || "",
  opdLink: process.env.REACT_APP_OPD_LINK || "",
  onlineLocationId: process.env.REACT_APP_ONLINE_LOCATION_ID || "",
  excludedDate: process.env.REACT_APP_EXCLUDED_DATE || "2024-09-18",
  timeSlotList: process.env.REACT_APP_TIME_SLOT_LIST || 1,
  bookingPage: process.env.REACT_APP_BOOKING_PAGE_TYPE || "1"
};
