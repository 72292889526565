import { api } from "app/api";
import * as ApiTypes from "./type";

const patientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createPatient: builder.mutation<
      ApiTypes.CreatePatientResponse,
      ApiTypes.CreatePatientRequest
    >({
      query: ({ data }) => ({
        url: `/patient/self-register`,
        method: `POST`,
        body: { data }
      })
    }),

    searchPatient: builder.query<
      ApiTypes.SearchPatientResponse,
      ApiTypes.SearchPatientRequest
    >({
      query: ({ code, contactNumber }) => ({
        url: "/patient/phone-number/search",
        method: `GET`,
        params: {
          code,
          contactNumber
        }
      })
    }),

    sendTotp: builder.mutation<
      ApiTypes.SendTotpResponse,
      ApiTypes.SendTotpRequest
    >({
      query: ({ data }) => ({
        url: `/otp/request`,
        method: `POST`,
        body: { data }
      })
    }),

    verifyTotp: builder.mutation<
      ApiTypes.VerifyTotpResponse,
      ApiTypes.VerifyTotpRequest
    >({
      query: ({ data }) => ({
        url: `/otp/verify`,
        method: `POST`,
        body: { data }
      })
    }),

    startPayment: builder.mutation<
      ApiTypes.StartPaymentResponse,
      ApiTypes.StartPaymentRequest
    >({
      query: (data) => ({
        url: `/payment/ekpay/request`,
        method: `POST`,
        body: data
      })
    }),

    requestAamarpayPayment: builder.mutation<
      ApiTypes.StartPaymentResponse,
      ApiTypes.StartPaymentRequest
    >({
      query: (data) => ({
        url: `/payment/aamarpay/request`,
        method: `POST`,
        body: data
      })
    }),

    finalizePayment: builder.mutation<
      ApiTypes.FinalizePaymentResponse,
      ApiTypes.FinalizePaymentRequest
    >({
      query: (data) => ({
        url: `/payment/ticket/success`,
        method: `POST`,
        body: data
      })
    }),

    getTicketByIdForPatient: builder.query<
      ApiTypes.GetTicketForPatientResponse,
      ApiTypes.GetTicketForPatientRequest
    >({
      query: ({ ticketId }) => ({
        url: `/patient/ticket/get`,
        method: `GET`,
        params: { ticketId }
      })
    }),

    getAllDepartments: builder.query<
      ApiTypes.GetAllDepartmentsResponse,
      ApiTypes.GetAllDepartmentsRequest
    >({
      query: () => ({
        url: `/departments/get`,
        method: `GET`
      })
    }),

    bookTicket: builder.mutation<
      ApiTypes.BookTicketResponse,
      ApiTypes.BookTicketRequest
    >({
      query: ({ data }) => ({
        url: `/online/ticket/create`,
        method: `POST`,
        body: { data }
      })
    }),

    getHospital: builder.query<
      ApiTypes.GetHospitalResponse,
      ApiTypes.GetHospitalRequest
    >({
      query: () => ({
        url: `/hospital/get`,
        method: `GET`
      })
    }),

    getPatientTicketHistory: builder.query<
      ApiTypes.GetPatientTicketHistoryResponse,
      ApiTypes.GetPatientTicketHistoryRequest
    >({
      query: ({ patientId, pageSize, pageIndex }) => ({
        url: `/patient/ticket/history/get`,
        method: `GET`,
        params: { patientId, pageSize, pageIndex }
      })
    }),

    validatePayment: builder.query<
      ApiTypes.ValidatePaymentResponse,
      ApiTypes.ValidatePaymentRequest
    >({
      query: ({ transactionId }) => ({
        url: `/payment/aamarpay/validate`,
        method: `GET`,
        params: { transactionId }
      })
    }),
    getPatientInformationFromNid: builder.query<
      ApiTypes.SearchPatientByHealthIdResponse,
      ApiTypes.GetPatientInformationFromNid
    >({
      query: ({ idType, idValue, dateOfBirth, name }) => ({
        url: `shr/self/find-patient-by-nid`,
        method: `GET`,
        params: { idType, idValue, dateOfBirth, name }
      })
    }),
    getOpdRegistryLocation: builder.query<
      ApiTypes.GetOpdRegistryLocationResponse,
      ApiTypes.GetOpdRegistryLocationRequest
    >({
      query: () => ({
        url: `/location/opd-registry/get`,
        method: `GET`
      })
    })
  }),

  overrideExisting: false
});

export const {
  useCreatePatientMutation,
  useLazySearchPatientQuery,
  useStartPaymentMutation,
  useRequestAamarpayPaymentMutation,
  useFinalizePaymentMutation,
  useSendTotpMutation,
  useVerifyTotpMutation,
  useGetAllDepartmentsQuery,
  useBookTicketMutation,
  useGetHospitalQuery,
  useGetPatientTicketHistoryQuery,
  useGetTicketByIdForPatientQuery,
  useValidatePaymentQuery,
  useLazyGetPatientInformationFromNidQuery,
  useGetOpdRegistryLocationQuery
} = patientApi;
