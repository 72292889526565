// this component is used for NICRH E-ticketing for patient

import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
  useDisclosure,
  FormControl,
  Input,
  FormLabel,
  useToast,
  IconButton,
  Tooltip,
  Text,
  HStack,
  Spacer,
  Checkbox,
  Link,
  Spinner
} from "@chakra-ui/react";
import { Location as LocationType, PatientWithDetails } from "app/api/type";
import dayjs from "dayjs";
import {
  useBookTicketMutation,
  useGetAllDepartmentsQuery,
  useGetOpdRegistryLocationQuery,
  useStartPaymentMutation
} from "../api";
import { Select } from "chakra-react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { useTypedSelector } from "hooks/use-typed-selector.hook";
import { FaInfoCircle, FaTicketAlt } from "react-icons/fa";
import { env } from "app/config";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

interface BookTicketModalComponentType2Props {
  patientWithDetails: PatientWithDetails;
}

export const BookTicketModalType2Component: React.FC<
  BookTicketModalComponentType2Props
> = (props: BookTicketModalComponentType2Props) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isSecondModalOpen,
    onOpen: onSecondModalOpen,
    onClose: onSecondModalClose
  } = useDisclosure();

  const toast = useToast();

  const [startPayment, startPaymentResult] = useStartPaymentMutation();

  const patientState = useTypedSelector((state) => state.patient);
  const getOpdRegistryLocationResult = useGetOpdRegistryLocationQuery({});
  const [bookTicket, bookTicketResult] = useBookTicketMutation();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<{
    label: string;
    value: number;
  }>();

  const timeSlotsList1 = [
    {
      label: "08:00 AM - 10:00 AM",
      value: 0
    },
    {
      label: "10:00 AM - 12:00 PM",
      value: 1
    },
    {
      label: "12:00 PM - 02:00 PM",
      value: 2
    }
  ];

  const timeSlotsList2 = [
    {
      label: "08:00 AM - 10:00 AM",
      value: 0
    },
    {
      label: "10:00 AM - 11:00 AM",
      value: 1
    }
  ];

  const [selectedDepartment, setSelectedDepartment] = useState<{
    value: string;
    label: string;
  }>();

  const [bookingDate, setBookingDate] = useState<Date | undefined>();

  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    if (isSecondModalOpen) {
      setCountdown(30);
      const interval = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isSecondModalOpen]);

  const filterDate = (date: Date) => {
    const day = date.getDay();
    return day !== 5;
  };

  useEffect(() => {
    if (
      getOpdRegistryLocationResult.data &&
      getOpdRegistryLocationResult.data.location
    ) {
      const nicrhOpdRegistryLocation =
        getOpdRegistryLocationResult.data.location.find(
          (location) => location.name === "NICRH OPD Registry"
        );
      setSelectedDepartment({
        value: nicrhOpdRegistryLocation ? nicrhOpdRegistryLocation.id : "",
        label: nicrhOpdRegistryLocation ? nicrhOpdRegistryLocation.name : ""
      });
    }
  }, [getOpdRegistryLocationResult]);

  const handleBookTicket = () => {
    if (bookingDate) {
      bookTicket({
        data: {
          otp: patientState.otp!,
          contactNumber: patientState.phoneNumber!,
          ticket: {
            patientId: props.patientWithDetails.patient.id,
            sourceLocationId: env.onlineLocationId,
            assignedLocationId: selectedDepartment
              ? selectedDepartment.value
              : "",
            ticketCategoryName: "Online",
            bookedFor: bookingDate,
            timeSlotDate: bookingDate,
            timeSlotLocationId: selectedDepartment
              ? selectedDepartment.value
              : "",
            timeSlotSerial: selectedTimeSlot ? selectedTimeSlot.value : 0,
            userId: null
          }
        }
      });
    }
  };

  useEffect(() => {
    if (bookTicketResult.isSuccess) {
      setBookingDate(undefined);
      onSecondModalOpen();
      startPayment({
        data: {
          ticketId: bookTicketResult.data.ticketWithDetails.ticket.id
        }
      });
    }
  }, [bookTicketResult]);

  useEffect(() => {
    if (startPaymentResult.isSuccess) {
      window.location.href = startPaymentResult.data.url;
    }
  }, [startPaymentResult]);

  return (
    <>
      <Button colorScheme={"blue"} onClick={onOpen} leftIcon={<FaTicketAlt />}>
        Book Ticket
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setBookingDate(undefined);
        }}
        isCentered
        size={{ base: "lg", lg: "5xl" }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Book Ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4">
              <FormControl isReadOnly>
                <FormLabel>Patient ID</FormLabel>
                <Input value={props.patientWithDetails.patient.id} />
              </FormControl>

              <FormControl isReadOnly>
                <FormLabel>Patient Name</FormLabel>
                <Input
                  value={
                    props.patientWithDetails.person.name
                      ? props.patientWithDetails.person.name
                      : "Unknown"
                  }
                />
              </FormControl>

              <FormControl id="department" isRequired>
                <FormLabel>Department</FormLabel>
                <Input
                  readOnly
                  value={selectedDepartment ? selectedDepartment.label : ""}
                  isReadOnly
                />
              </FormControl>

              <FormControl id="timeSlot" isRequired>
                <FormLabel>Time Slot</FormLabel>
                <Select
                  menuPlacement="top"
                  selectedOptionStyle="check"
                  options={
                    env.timeSlotList === "1" ? timeSlotsList1 : timeSlotsList2
                  }
                  value={selectedTimeSlot}
                  onChange={(event) => {
                    if (event) {
                      setSelectedTimeSlot({
                        label: event.label,
                        value: event.value
                      });
                    }
                  }}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Booking Date</FormLabel>
                <DatePicker
                  selected={bookingDate}
                  filterDate={filterDate}
                  onChange={(date) => date && setBookingDate(date)}
                  dateFormat="dd-MM-yyyy"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  // minDate should be tomorrow if 12 PM has passed in GMT+6
                  minDate={
                    dayjs().tz("Asia/Dhaka").hour() >= 11
                      ? dayjs().tz("Asia/Dhaka").add(1, "day").day() === 5
                        ? dayjs().tz("Asia/Dhaka").add(2, "day").toDate()
                        : dayjs().tz("Asia/Dhaka").add(1, "day").toDate()
                      : dayjs().tz("Asia/Dhaka").toDate()
                  }
                  maxDate={dayjs().tz("Asia/Dhaka").add(7, "days").toDate()}
                  excludeDates={[dayjs(env.excludedDate).toDate()]}
                />
              </FormControl>

              <Checkbox defaultChecked>
                <Text as="span">
                  I agree to the{" "}
                  <Link
                    href="/terms-and-conditions"
                    color="blue.500"
                    isExternal
                  >
                    Terms & Conditions
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="/terms-and-conditions"
                    color="blue.500"
                    isExternal
                  >
                    Refund Policy
                  </Link>
                </Text>
              </Checkbox>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <HStack spacing={4} justifyContent="space-between" w="100%">
              <Tooltip
                label={
                  <>
                    <Text>Ticket Price : ৳10 </Text>
                    <Text>Conveyance Charge : ৳0.5 </Text>
                  </>
                }
                placement="top"
              >
                <IconButton
                  aria-label="View Price"
                  icon={<FaInfoCircle color="blue" />}
                  variant="transparent"
                />
              </Tooltip>
              <Spacer />
              <Button
                colorScheme="red"
                mr={3}
                onClick={() => {
                  onClose();
                  setBookingDate(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                isDisabled={!bookingDate || !selectedDepartment}
                colorScheme="blue"
                onClick={handleBookTicket}
                isLoading={startPaymentResult.isLoading}
              >
                Book Ticket
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isSecondModalOpen}
        isCentered
        onClose={onSecondModalClose}
        size="full"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Redirecting</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              spacing="4"
              align={"center"}
              justify="center"
              minH="80vh"
              display="flex"
            >
              <Stack spacing="4" align="center">
                <Text fontFamily={"heading"} textAlign="center">
                  You are being directed to payment gateway in{" "}
                  <Text as="span" fontWeight={"bold"}>
                    {countdown} seconds
                  </Text>
                </Text>
                <Spinner color="blue.500" size={"lg"} />
                <Text
                  fontWeight={"bold"}
                  fontFamily={"heading"}
                  textAlign="center"
                >
                  Do not close this tab or refresh the page
                </Text>
                <Text
                  // fontWeight={"bold"}
                  fontFamily={"heading"}
                  textAlign="center"
                >
                  আপনাকে পেমেন্ট গেটওয়েতে নিয়ে যাওয়া হচ্ছে। অনুগ্রহ করে অপেক্ষা
                  করুন। পেজটি রিফ্রেশ করবেন না।
                </Text>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
